<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    id: string;
    placeholder?: string;
    modelValue?: string;
    valid?: boolean;
    error?: string;
  }>(),
  {
    placeholder: "",
    valid: true,
    error: "",
    modelValue: "",
  }
);

const emit = defineEmits(["update:modelValue", "input", "change"]);

const updateValue = (event: any) => {
  emit("update:modelValue", event.target.value);
  emit("input");
};
</script>

<template>
  <textarea
    v-bind="$attrs"
    :id="id"
    :value="props.modelValue"
    :name="id"
    class="w-full h-32 flex-grow rounded border p-1.5 px-2.5 border-1 border-maas-border-grey-normal focus:outline-1 focus:outline-maas-border-primary"
    :class="{
      'border-red-700 outline-red-700': !props.valid,
      'border-maas-border-grey-normal outline-maas-border-grey-normal': props.valid,
    }"
    :placeholder="placeholder"
    @input="updateValue"
    @change="emit('change')"
  ></textarea>
  <div v-if="!props.valid" class="mt-2 text-red-700">{{ props.error }}</div>
</template>
